import ErrorBoundary from './components/ErrorBoundary'

import './App.css'
import Layout from './components/Layout'
import IndexView from './components/IndexView'

const App = (): JSX.Element => (
  <Layout>
    <ErrorBoundary>
      <IndexView />
    </ErrorBoundary>
  </Layout>
)

export default App
