import { hydrateRoot } from 'react-dom/client'

import App from './App'

const container = document.getElementById('root') as HTMLElement

hydrateRoot(container, <App />)

if (module.hot != null) {
  module.hot.accept()
}
