import { Fragment, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Alert from 'react-bootstrap/Alert'
import { Search, CaretRight } from 'react-bootstrap-icons'
import appCategories from '../lib/apps'

const IndexView = (): JSX.Element => {
  const [search, setSearch] = useState<string>('')

  const trimSearch = search.trim().toLowerCase()
  let filteredApps = appCategories
  if (trimSearch !== '') {
    filteredApps = appCategories.map(cat => ({
      ...cat,
      apps: cat.apps.filter(app => app.name.toLowerCase().includes(trimSearch))
    })).filter(cat => cat.apps.length > 0)
  }

  return (
    <>
      <InputGroup className='my-3'>
        <InputGroup.Prepend>
          <InputGroup.Text id='search-icon'><Search /></InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder='Search apps...'
          value={search}
          onChange={e => setSearch(e.target.value)}
          aria-label='Search apps'
          aria-describedby='search-icon'
        />
      </InputGroup>

      {filteredApps.length > 0
        ? filteredApps.map(({ key, name, apps }) => (
          <div key={key} id={key}>
            {/* <CatHeader name={name} /> */}
            <ListGroup className='my-2' activeKey=''>
              <ListGroup.Item variant='primary'>{name}</ListGroup.Item>
              {apps.map(({ name, link, variant, message }) => (
                <ListGroup.Item
                  key={name}
                  action={link != null}
                  href={link}
                  target='_blank'
                  rel='noopener'
                  variant={variant}
                >
                  <Row>
                    <Col>
                      {name}
                      {message != null && <small className='pl-3'>{message}</small>}
                    </Col>
                    {link != null && <Col xs='auto'><CaretRight /></Col>}
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        ))
        : <Alert variant='warning'>No results found for &ldquo;{search}&rdquo;</Alert>}
    </>
  )
}

export default IndexView
