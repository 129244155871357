import { Component, PropsWithChildren, ReactNode } from 'react'

import { ErrorMessage, ForbiddenMessage } from './alerts'

export class ForbiddenError extends Error {}

interface ErrorState {
  error: Error | null
}

class ErrorBoundary extends Component<PropsWithChildren> {
  state: ErrorState = { error: null }

  static getDerivedStateFromError (error: Error): ErrorState {
    return { error }
  }

  render (): ReactNode {
    const { error } = this.state
    if ((error != null) && error instanceof ForbiddenError) {
      return <ForbiddenMessage forbiddenItem={error.message} />
    } else if (error != null) {
      return <ErrorMessage message={error.message} />
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
