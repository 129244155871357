import Navbar from 'react-bootstrap/Navbar'

const MainNav = (): JSX.Element => (
  <>
    <Navbar bg='primary' variant='dark'>
      <a href='/'>
        <img src='https://static.da-io.net/images/da-logo-white-text-no-bg.svg' alt='DA logo' height='38' />
      </a>
      <a href='/'>
        <Navbar.Brand>
          Apps
        </Navbar.Brand>
      </a>
    </Navbar>
  </>
)

export default MainNav
